html,
body,
#root {
    width: 100%;
    height: 100%;
}

em {
    color: #ffffff;
    font-style: normal;
    text-shadow: 0 0 5px #ffffff;
}

code {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
    border: 1px solid #404040;
    background: rgba(16, 16, 26, 0.6);
}

a {
    text-decoration: none;
    color: #4AF626;
}


a:hover {
    color: #4AF626;
    font-style: normal;
    text-shadow: 0 0 5px #4AF626;
}

@keyframes glow {
    0% {
        text-shadow: 0 0 10px #ffd700;
    }

    50% {
        text-shadow: 0 0 50px #ffd700;
    }

    100% {
        text-shadow: 0 0 10px #ffd700;
    }
}

.mainEvent:hover {
    color: #ffd700;
    font-style: normal;
    animation: glow 5s ease-in-out infinite;
}